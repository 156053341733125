import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
  },
  computed: {
    isFinished() {
      if(!this.requiredFinished) return false
      if(!this.isValidEmail) return false
      if(!this.customValidateResult) return false
      return true
    },
    vuetifyRules() {
      let rules = window.eagleLodash.cloneDeep(this.baseVuetifyRules)
      if(this.isEmailType) {
        rules.push(this.emailRule)
      }
      if(this.minLengthRule) {
        rules.push(this.minLengthRule)
      }
      return rules
    },
    minLengthRule() {
      if(!this.formDataItem.minlength) return null
      return this.$helper.validator.minTextRule(this.data, this.formDataItem.minlength)
    },
    isEmailType() {
      return this.formDataItem.email === true
    },
    isValidEmail() {
      if(!this.isEmailType) return true
      if(!this.required) {
        if(!this.data) return true
      }
      if(!this.data) return false
      return this.$helper.validator.email(this.data)
    },
    emailRule() {
      return value => this.$helper.validator.emailRule(this.data)
    },
    maxlength() {
      return this.formDataItem.maxlength || 50
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  }
}
